// src/app/services/idle.service.ts
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { fromEvent, merge, Observable, timer } from 'rxjs';
import { switchMapTo, takeUntil } from 'rxjs/operators';
import { GlobalProvider } from "../../providers/global/global";
import { V2appProvider } from "../../providers/v2app/v2app";
import { Storage } from '@ionic/storage';

@Injectable({
  providedIn: 'root'
})
export class IdleService {
  private readonly idleTimeout = 10 * 60 * 1000; // 20 minutes in milliseconds

  constructor(private router: Router, public global: GlobalProvider, public API: V2appProvider, public storage: Storage) {}

  startWatching(): void {
    const idle$: Observable<any> = timer(this.idleTimeout);
    const userActivity$ = merge(
      fromEvent(document, 'mousemove'),
      fromEvent(document, 'click'),
      fromEvent(document, 'keypress')
    );

    userActivity$.pipe(
      switchMapTo(idle$)
    ).subscribe(() => {
      this.onIdleTimeout();
    });
  }

  private onIdleTimeout(): void {

    this.API.userlogout(this.global.usertoken).then((result) => {
      if (result['data'].length == 0) {
        this.global.showAlert("logout","logout failed");
        return false;
      } else {
        console.log("user logout successful"); 
        //this.storage.clear();
        this.global.usertoken = null;
        this.global.tabsEnabled = false;
        this.global.name= null;
        this.global.email = null;
        this.global.mybalance = null;
        this.global.mobileno = null;
        this.global.username = null;
        
        this.storage.remove('usertoken').then((val) => {
          // Redirect to the login page
          this.router.navigate(['/my/en-us/login']);
        }).catch(function(reject) {
              console.log('reject');
        });
        
        //window.location.reload();
        //this.navCtrl.parent.parent.setRoot(this.loginPage);
        //this.navCtrl.push(this.loginPage);
        
      }
    }).catch(function(reject) {
      console.log('reject');
      return false;
    })
  }
}
