import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GlobalProvider } from "../../providers/global/global";
import { NavController } from '@ionic/angular';

/*
  Generated class for the V2appProvider provider.

  See https://angular.io/guide/dependency-injection for more info on providers
  and Angular DI.
*/
@Injectable()
export class V2appProvider {

  //credential = btoa('test' + ":" + 'test');
  //agent_id = "011111111";
  //agent_password = "toptal123";
  //url_api = "http://35.240.142.0/api/";
  url_api: string;
  //url_api = "http://192.168.64.2/v2api/public/api/";

  credential = btoa('test' + ":" + 'test');
  //url_api = "https://www.918k1ss.com/genericsms/";

  constructor(public http: HttpClient, public navCtrl: NavController, public global: GlobalProvider) {
    console.log('Hello V2appProvider Provider');
    this.url_api = this.global.url;
  }

  /*agentlogin(){
    return new Promise((resolve, reject) =>{
      var configUrl = this.url_api + "login";
      let headers= new HttpHeaders({
        'Content-Type' : 'application/json',
        //'Customer-Key' : '114057'
      });
      let data = {
        mobileno : this.agent_id,
        password : this.agent_password
      };
      this.http
        .post(configUrl,JSON.stringify(data),{headers:headers})
        .subscribe((res:any) => {
          console.log(res.data.api_token)
          resolve(res);
        }, (err) =>{
          reject(err);
        });
    });
  }*/

  userlogin(lmobileno:string, luserpwd:string, lusername:string){
    return new Promise((resolve, reject) =>{
      var configUrl = this.url_api + "vtbetlogin";
      let headers= new HttpHeaders({
        'Content-Type' : 'application/json',
        'Accept' : 'application/json',
        //'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8',
        //'Customer-Key' : '114057'
      });
      let data = {
        mobileno : lmobileno,
        password : luserpwd,
        username: lusername,
        app_secret : this.global.app_secret
      };
      this.http
        .post(configUrl,JSON.stringify(data),{headers:headers})
        .subscribe((res:any) => {
          //console.log(res.data.api_token)
          resolve(res);
        }, (err) =>{
          reject(err);
        });
    });
  }

  // get the user settings
  agentsetting(lapp_secret:string){
    return new Promise((resolve, reject) =>{
      var configUrl = this.url_api + "agentsetting";
      let headers= new HttpHeaders({
        'Accept' : 'application/json',
        'Content-Type' : 'application/json'
        //'Customer-Key' : '114057'
      });
      let data = {
        app_secret : lapp_secret
      };
      this.http
        .post(configUrl,JSON.stringify(data),{headers:headers})
        .subscribe((res:any) => {
          console.log(res.data.length);
          resolve(res);
        }, (err) =>{
          reject(err);
        });
    });
  }

  viewbank(lusertoken:string){
    return new Promise((resolve, reject) =>{
      var configUrl = this.url_api + "viewbank";
      var lbearer = "Bearer " + lusertoken;
      let headers= new HttpHeaders({
        'Accept' : 'application/json',
        'Content-Type' : 'application/json',
        'Authorization' : lbearer
      });
      let data = {
        dummy : "1"
      };
      this.http
        .post(configUrl,JSON.stringify(data),{headers:headers})
        .subscribe((res:any) => {
          console.log(res.data.length);
          resolve(res);
        }, (err) =>{
          if(err.status==500) {
            this.global.showAlert("Error","User session timeout, please relogin");
            this.navCtrl.navigateForward('/my/en-us/login');
          }
        });
    });
  }

  getbal(lusertoken:string){
    return new Promise((resolve, reject) =>{
      var configUrl = this.url_api + "getbal";
      var lbearer = "Bearer " + lusertoken;
      let headers= new HttpHeaders({
        'Accept' : 'application/json',
        'Content-Type' : 'application/json',
        'Authorization' : lbearer
      });
      let data = {
        dummy : "1"
      };
      this.http
        .post(configUrl,JSON.stringify(data),{headers:headers})
        .subscribe((res:any) => {
          console.log(res.data.length);
          resolve(res);
        }, (err) =>{
          reject(err);
        });
    });
  }

  editbal(lusertoken:string, lamount:number){
    return new Promise((resolve, reject) =>{
      var configUrl = this.url_api + "editbal";
      var lbearer = "Bearer " + lusertoken;
      let headers= new HttpHeaders({
        'Accept' : 'application/json',
        'Content-Type' : 'application/json',
        'Authorization' : lbearer
      });
      let data = {
        amount : lamount
      };
      this.http
        .post(configUrl,JSON.stringify(data),{headers:headers})
        .subscribe((res:any) => {
          console.log(res.data.length);
          resolve(res);
        }, (err) =>{
          reject(err);
        });
    });
  }

  addbank(lusertoken:string, lbankname:string, laccno:string, laccname:string){
    return new Promise((resolve, reject) =>{
      var configUrl = this.url_api + "addbank";
      var lbearer = "Bearer " + lusertoken;
      let headers= new HttpHeaders({
        'Accept' : 'application/json',
        'Content-Type' : 'application/json',
        'Authorization' : lbearer
      });
      let data = {
        bankname : lbankname,
        accno : laccno,
        accname : laccname
      };
      //console.log("add bank data is " + lbankname + "|" + laccno + "|" + laccname);
      this.http
        .post(configUrl,JSON.stringify(data),{headers:headers})
        .subscribe((res:any) => {
          console.log(res.length);
          resolve(res);
        }, (err) =>{
          if(err.status==500) {
            this.global.showAlert("Error","User session timeout, please relogin");
            this.navCtrl.navigateForward('/my/en-us/login');
          }
        });
    });
  }

  // get the check bonus settings
  checkbonuslimit(lusertoken:string, lamount:number, lbonusindex:number){
    return new Promise((resolve, reject) =>{
      var configUrl = this.url_api + "checkbonuslimit";
      var lbearer = "Bearer " + lusertoken;
      let headers= new HttpHeaders({
        'Accept' : 'application/json',
        'Content-Type' : 'application/json',
        'Authorization' : lbearer
        //'Customer-Key' : '114057'
      });
      let data = {
        amount : lamount,
        bonusid : lbonusindex
      };
      this.http
        .post(configUrl,JSON.stringify(data),{headers:headers})
        .subscribe((res:any) => {
          resolve(res);
        }, (err) =>{
          reject(err);
        });
    });
  }

  delbank(lusertoken:string, laccno:string){
    return new Promise((resolve, reject) =>{
      var configUrl = this.url_api + "delbank";
      var lbearer = "Bearer " + lusertoken;
      let headers= new HttpHeaders({
        'Accept' : 'application/json',
        'Content-Type' : 'application/json',
        'Authorization' : lbearer
      });
      let data = {
        accno : laccno
      };
      //console.log("add bank data is " + lbankname + "|" + laccno + "|" + laccname);
      this.http
        .post(configUrl,JSON.stringify(data),{headers:headers})
        .subscribe((res:any) => {
          console.log(res.length);
          resolve(res);
        }, (err) =>{
          if(err.status==500) {
            this.global.showAlert("Error","User session timeout, please relogin");
            this.navCtrl.navigateForward('/my/en-us/login');
          }
        });
    });
  }

  createwithdrawal(lusertoken:string, lbankacc_id:string,lamount:string, lgame: string, lmd5str:string){
    return new Promise((resolve, reject) =>{
      var configUrl = this.url_api + "createwithdrawal";
      var lbearer = "Bearer " + lusertoken;
      let headers= new HttpHeaders({
        'Accept' : 'application/json',
        'Content-Type' : 'application/json',
        'Authorization' : lbearer
        //'Customer-Key' : '114057'
      });
      let data = {
        bankacc_id: lbankacc_id,
        amount: lamount,
        fromgame: lgame,
        token: lmd5str
      };
      this.http
        .post(configUrl,JSON.stringify(data),{headers:headers})
        .subscribe((res:any) => {
          //console.log(res.data.length);
          resolve(res);
        }, (err) =>{
          if(err.status==500) {
            this.global.showAlert("Error","User session timeout, please relogin");
            this.navCtrl.navigateForward('/my/en-us/login');
          }
        });
    });
  }

  viewwithdrawal(lusertoken:string, lstartdate:string, lenddate:string, lstatus:string){
    return new Promise((resolve, reject) =>{
      var configUrl = this.url_api + "viewwithdrawal";
      var lbearer = "Bearer " + lusertoken;
      let headers= new HttpHeaders({
        'Accept' : 'application/json',
        'Content-Type' : 'application/json',
        'Authorization' : lbearer
        //'Customer-Key' : '114057'
      });
      let data = {
        startdate: lstartdate,
        enddate: lenddate,
        status: lstatus
      };
      this.http
        .post(configUrl,JSON.stringify(data),{headers:headers})
        .subscribe((res:any) => {
          console.log(res.data.length);
          resolve(res);
        }, (err) =>{
          if(err.status==500) {
            this.global.showAlert("Error","User session timeout, please relogin");
            this.navCtrl.navigateForward('/my/en-us/login');
          }
        });
    });
  }

  userlogout(lusertoken:string){
    return new Promise((resolve, reject) =>{
      var configUrl = this.url_api + "logout";
      var lbearer = "Bearer " + lusertoken;
      let headers= new HttpHeaders({
        'Accept' : 'application/json',
        'Content-Type' : 'application/json',
        'Authorization' : lbearer
        //'Customer-Key' : '114057'
      });
      let data = {
        dummy : "1"
      };
      this.http
        .post(configUrl,JSON.stringify(data),{headers:headers})
        .subscribe((res:any) => {
          console.log(res.data.length);
          resolve(res);
        }, (err) =>{
          reject(err);
        });
    });
  }

  sendotp(lmobileno:string, lapp_secret:string){
    return new Promise((resolve, reject) =>{
      var configUrl = this.url_api + "sendotp";
      let headers= new HttpHeaders({
        'Accept' : 'application/json',
        'Content-Type' : 'application/json'
      });
      let data = {
        mobileno : lmobileno,
        app_secret : lapp_secret
      };
      this.http
        .post(configUrl,JSON.stringify(data),{headers:headers})
        .subscribe((res:any) => {
          console.log(res.error);
          resolve(res);
        }, (err) =>{
          reject(err);
        });
    });
  }

  viewaffiliate(lusertoken:string, lstartdate:string, lenddate:string){
    return new Promise((resolve, reject) =>{
      var configUrl = this.url_api + "viewaffiliate";
      var lbearer = "Bearer " + lusertoken;
      let headers= new HttpHeaders({
        'Accept' : 'application/json',
        'Content-Type' : 'application/json',
        'Authorization' : lbearer
        //'Customer-Key' : '114057'
      });
      let data = {
        startdate: lstartdate,
        enddate: lenddate
      };
      this.http
        .post(configUrl,JSON.stringify(data),{headers:headers})
        .subscribe((res:any) => {
          resolve(res);
        }, (err) =>{
          if(err.status==500) {
            this.global.showAlert("Error","User session timeout, please relogin");
            this.navCtrl.navigateForward('/my/en-us/login');
          }
        });
    });
  
  }


  viewaffplayer(lusertoken:string, lstartdate:string, lenddate:string){
    return new Promise((resolve, reject) =>{
      var configUrl = this.url_api + "viewaffplayer";
      var lbearer = "Bearer " + lusertoken;
      let headers= new HttpHeaders({
        'Accept' : 'application/json',
        'Content-Type' : 'application/json',
        'Authorization' : lbearer
        //'Customer-Key' : '114057'
      });
      let data = {
        startdate: lstartdate,
        enddate: lenddate
      };
      this.http
        .post(configUrl,JSON.stringify(data),{headers:headers})
        .subscribe((res:any) => {
          console.log(res.data.length);
          resolve(res);
        }, (err) =>{
          if(err.status==500) {
            this.global.showAlert("Error","User session timeout, please relogin");
            this.navCtrl.navigateForward('/my/en-us/login');
          }
        });
    });
  }

  mostactive(lusertoken:string){
    return new Promise((resolve, reject) =>{
      var configUrl = this.url_api + "mostactive";
      var lbearer = "Bearer " + lusertoken;
      let headers= new HttpHeaders({
        'Accept' : 'application/json',
        'Content-Type' : 'application/json',
        'Authorization' : lbearer
        //'Customer-Key' : '114057'
      });
      let data = {
        dummy: "1"
      };
      this.http
        .post(configUrl,JSON.stringify(data),{headers:headers})
        .subscribe((res:any) => {
          console.log(res.data.length);
          resolve(res);
        }, (err) =>{
          if(err.status==500) {
            this.global.showAlert("Error","User session timeout, please relogin");
            this.navCtrl.navigateForward('/my/en-us/login');
          }
        });
    });
  }

  verifyotp(lmobileno:string, lotp:string){
    console.log("verifyotp is " + lmobileno + " and [" + lotp + "]");
    return new Promise((resolve, reject) =>{
      var configUrl = this.url_api + "verifyotp";
      let headers= new HttpHeaders({
        'Accept' : 'application/json',
        'Content-Type' : 'application/json'
      });
      let data = {
        otp : lotp,
        mobileno : lmobileno
      };
      this.http
        .post(configUrl,JSON.stringify(data),{headers:headers})
        .subscribe((res:any) => {
          console.log(res.error);
          resolve(res);
        }, (err) =>{
          reject(err);
        });
    });
  }

  checkmobileno(lmobileno:string){

    return new Promise((resolve, reject) =>{
      var configUrl = this.url_api + "viewuser";
      let headers= new HttpHeaders({
        'Accept' : 'application/json',
        'Content-Type' : 'application/json'
      });
      let data = {
        mobileno : lmobileno,
        app_secret : this.global.app_secret
      };
      this.http
        .post(configUrl,JSON.stringify(data),{headers:headers})
        .subscribe((res:any) => {
          //console.log(res.error);
          resolve(res);
        }, (err) =>{
          reject(err);
        });
    });
  }

  register(lmobileno:string, lpassword:string, lapp_secret:string, ltoken:string, lrefcode:string, lregchannel:number){
    return new Promise((resolve, reject) =>{
      var configUrl = this.url_api + "newregister";
      let headers= new HttpHeaders({
        'Accept' : 'application/json',
        'Content-Type' : 'application/json'
      });
      let data = {
        mobileno : lmobileno,
        email : lmobileno + "@gmail.com",
        password : lpassword,
        confirm : lpassword,
        app_secret : lapp_secret,
        token : ltoken,
        lrefcode : lrefcode,
        regchannel : lregchannel
      };

      this.http
        .post(configUrl,JSON.stringify(data),{headers:headers})
        .subscribe((res:any) => {
          console.log(res.error);
          resolve(res);
        }, (err) =>{
          reject(err);
        });
    });
  }

  forgotpasswd(lmobileno:string, lpassword:string, lapp_secret:string){
    return new Promise((resolve, reject) =>{
      var configUrl = this.url_api + "forgotpasswd";
      let headers= new HttpHeaders({
        'Accept' : 'application/json',
        'Content-Type' : 'application/json'
      });
      let data = {
        mobileno : lmobileno,
        email : lmobileno + "@gmail.com",
        password : lpassword,
        app_secret : lapp_secret
      };
      this.http
        .post(configUrl,JSON.stringify(data),{headers:headers})
        .subscribe((res:any) => {
          console.log(res.status);
          resolve(res);
        }, (err) =>{
          reject(err);
        });
    });
  }

  resetpasswd(lusertoken:string, lpassword:string,){
    return new Promise((resolve, reject) =>{
      var configUrl = this.url_api + "resetpasswd";
      var lbearer = "Bearer " + lusertoken;
      //console.log("bearer is " + lbearer);
      let headers= new HttpHeaders({
        'Accept' : 'application/json',
        'Content-Type' : 'application/json',
        'Authorization' : lbearer
        //'Customer-Key' : '114057'
      });
      let data = {
        password: lpassword
      };
      this.http
        .post(configUrl,JSON.stringify(data),{headers:headers})
        .subscribe((res:any) => {
          console.log(res.status);
          resolve(res);
        }, (err) =>{
          if(err.status==500) {
            this.global.showAlert("Error","User session timeout, please relogin");
            this.navCtrl.navigateForward('/my/en-us/login');
          }
        });
    });
  }

  checkversion(lapp_secret:string){
    return new Promise((resolve, reject) =>{
      var configUrl = this.url_api + "checkversion";
      let headers= new HttpHeaders({
        'Accept' : 'application/json',
        'Content-Type' : 'application/json'
      });
      let data = {
        app_secret : lapp_secret
      };
      this.http
        .post(configUrl,JSON.stringify(data),{headers:headers})
        .subscribe((res:any) => {
          resolve(res);
        }, (err) =>{
          reject(err);
        });
    });
  }

  // 918kiss APIs 
  kiss918v2create(lusertoken:string) {
    return new Promise((resolve, reject) =>{
      var configUrl = this.url_api + "kiss918v2create";
      var lbearer = "Bearer " + lusertoken;
      
      let headers= new HttpHeaders({
        'Accept' : 'application/json',
        'Content-Type' : 'application/json',
        'Authorization' : lbearer
      });
      let data = {
        dummy: "1"
      };
      this.http
        .post(configUrl,JSON.stringify(data),{headers:headers})
        .subscribe((res:any) => {
          resolve(res);
        }, (err) =>{
          reject(err);
        });
    });
  }

  // 918kiss APIs
  kiss918playerinfo(lusertoken:string){
    return new Promise((resolve, reject) =>{
      var configUrl = this.url_api + "kiss918playerinfo";
      var lbearer = "Bearer " + lusertoken;
      
      let headers= new HttpHeaders({
        'Accept' : 'application/json',
        'Content-Type' : 'application/json',
        'Authorization' : lbearer
      });
      let data = {
        dummy: "1"
      };
      this.http
        .post(configUrl,JSON.stringify(data),{headers:headers})
        .subscribe((res:any) => {
          resolve(res);
        }, (err) =>{
          reject(err);
        });
    });
  }

  kiss918credit(lusertoken:string, lamt:string){
    return new Promise((resolve, reject) =>{
      var configUrl = this.url_api + "kiss918credit";
      var lbearer = "Bearer " + lusertoken;
      
      let headers= new HttpHeaders({
        'Accept' : 'application/json',
        'Content-Type' : 'application/json',
        'Authorization' : lbearer
      });
      let data = {
        amt: lamt
      };
      this.http
        .post(configUrl,JSON.stringify(data),{headers:headers})
        .subscribe((res:any) => {
          resolve(res);
        }, (err) =>{
          reject(err);
        });
    });
  }

  kiss918creditbyuserid(luserid:string, lamt:string) {
    return new Promise((resolve, reject) =>{
      var configUrl = this.url_api + "kiss918creditbyuserid";
      //var lbearer = "Bearer " + lusertoken;
      
      let headers= new HttpHeaders({
        'Accept' : 'application/json',
        'Content-Type' : 'application/json',
        //'Authorization' : lbearer
      });
      let data = {
        "userid": luserid,
        "amt" : lamt
      };
      this.http
        .post(configUrl,JSON.stringify(data),{headers:headers})
        .subscribe((res:any) => {
          resolve(res);
        }, (err) =>{
          reject(err);
        });
    });
  }

  kiss918reset(lusertoken:string){
    return new Promise((resolve, reject) =>{
      var configUrl = this.url_api + "kiss918resetpassword";
      var lbearer = "Bearer " + lusertoken;
      
      let headers= new HttpHeaders({
        'Accept' : 'application/json',
        'Content-Type' : 'application/json',
        'Authorization' : lbearer
      });
      let data = {
        "dummy": "1"
      };
      this.http
        .post(configUrl,JSON.stringify(data),{headers:headers})
        .subscribe((res:any) => {
          resolve(res);
        }, (err) =>{
          reject(err);
        });
    });
  }

  // API for transfer
  transfercreate(lusertoken:string, lfromgame:string, ltogame:string, lamt:string){
    return new Promise((resolve, reject) =>{
      var configUrl = this.url_api + "transfercreate";
      var lbearer = "Bearer " + lusertoken;
      
      let headers= new HttpHeaders({
        'Accept' : 'application/json',
        'Content-Type' : 'application/json',
        'Authorization' : lbearer
      });
      let data = {
        fromgame: lfromgame,
        togame: ltogame,
        amt: lamt
      };
      this.http
        .post(configUrl,JSON.stringify(data),{headers:headers})
        .subscribe((res:any) => {
          resolve(res);
        }, (err) =>{
          if(err.status==500) {
            this.global.showAlert("Error","User session timeout, please relogin");
            this.navCtrl.navigateForward('/my/en-us/login');
          }
        });
    });
  }

  transferupdate(lusertoken:string, ltrxid:number, lstatus:string, lmessage:string, lbalance:string, ltype:string){
    return new Promise((resolve, reject) =>{
      var configUrl = this.url_api + "transferupdate";
      var lbearer = "Bearer " + lusertoken;
      
      let headers= new HttpHeaders({
        'Accept' : 'application/json',
        'Content-Type' : 'application/json',
        'Authorization' : lbearer
      });
      let data = {
        trxid: ltrxid,
        status: lstatus,
        message: lmessage,
        balance: lbalance,
        type: ltype
      };
      this.http
        .post(configUrl,JSON.stringify(data),{headers:headers})
        .subscribe((res:any) => {
          resolve(res);
        }, (err) =>{
          if(err.status==500) {
            this.global.showAlert("Error","User session timeout, please relogin");
            this.navCtrl.navigateForward('/my/en-us/login');
          }
        });
    });
  }

  // xe88 APIs
  xe88playerinfo(lusertoken:string){
    return new Promise((resolve, reject) =>{
      var configUrl = this.url_api + "xe88playerinfo";
      var lbearer = "Bearer " + lusertoken;
      
      let headers= new HttpHeaders({
        'Accept' : 'application/json',
        'Content-Type' : 'application/json',
        'Authorization' : lbearer
      });
      let data = {
        dummy: "1"
      };
      this.http
        .post(configUrl,JSON.stringify(data),{headers:headers})
        .subscribe((res:any) => {
          resolve(res);
        }, (err) =>{
          reject(err);
        });
    });
  }

  xe88credit(lusertoken:string, lamt:string) {
    return new Promise((resolve, reject) =>{
      var configUrl = this.url_api + "xe88credit";
      var lbearer = "Bearer " + lusertoken;
      
      let headers= new HttpHeaders({
        'Accept' : 'application/json',
        'Content-Type' : 'application/json',
        'Authorization' : lbearer
      });
      let data = {
        amount : lamt
      };
      this.http
        .post(configUrl,JSON.stringify(data),{headers:headers})
        .subscribe((res:any) => {
          resolve(res);
        }, (err) =>{
          reject(err);
        });
    });
  }

  xe88reset(lusertoken:string){
    return new Promise((resolve, reject) =>{
      var configUrl = this.url_api + "xe88resetpassword";
      var lbearer = "Bearer " + lusertoken;
      
      let headers= new HttpHeaders({
        'Accept' : 'application/json',
        'Content-Type' : 'application/json',
        'Authorization' : lbearer
      });
      let data = {
        "dummy": "1"
      };
      this.http
        .post(configUrl,JSON.stringify(data),{headers:headers})
        .subscribe((res:any) => {
          resolve(res);
        }, (err) =>{
          reject(err);
        });
    });
  }

  // xe88 APIs
  mega888playerinfo(lusertoken:string){
    return new Promise((resolve, reject) =>{
      var configUrl = this.url_api + "mega888playerinfo";
      var lbearer = "Bearer " + lusertoken;
      
      let headers= new HttpHeaders({
        'Accept' : 'application/json',
        'Content-Type' : 'application/json',
        'Authorization' : lbearer
      });
      let data = {
        dummy: "1"
      };
      this.http
        .post(configUrl,JSON.stringify(data),{headers:headers})
        .subscribe((res:any) => {
          resolve(res);
        }, (err) =>{
          reject(err);
        });
    });
  }

  // xe88 APIs
  xe88create(lusertoken:string) {
    return new Promise((resolve, reject) =>{
      var configUrl = this.url_api + "xe88create";
      var lbearer = "Bearer " + lusertoken;
      
      let headers= new HttpHeaders({
        'Accept' : 'application/json',
        'Content-Type' : 'application/json',
        'Authorization' : lbearer
      });
      let data = {
        dummy: "1"
      };
      this.http
        .post(configUrl,JSON.stringify(data),{headers:headers})
        .subscribe((res:any) => {
          resolve(res);
        }, (err) =>{
          reject(err);
        });
    });
  }

  // mega888 APIs
  mega888create(lusertoken:string) {
    return new Promise((resolve, reject) =>{
      var configUrl = this.url_api + "mega888create";
      var lbearer = "Bearer " + lusertoken;
      
      let headers= new HttpHeaders({
        'Accept' : 'application/json',
        'Content-Type' : 'application/json',
        'Authorization' : lbearer
      });
      let data = {
        dummy: "1"
      };
      this.http
        .post(configUrl,JSON.stringify(data),{headers:headers})
        .subscribe((res:any) => {
          resolve(res);
        }, (err) =>{
          reject(err);
        });
    });
  }

  mega888credit(lusertoken:string, lamt:string){
    return new Promise((resolve, reject) =>{
      var configUrl = this.url_api + "mega888credit";
      var lbearer = "Bearer " + lusertoken;
      
      let headers= new HttpHeaders({
        'Accept' : 'application/json',
        'Content-Type' : 'application/json',
        'Authorization' : lbearer
      });
      let data = {
        amount : lamt
      };
      this.http
        .post(configUrl,JSON.stringify(data),{headers:headers})
        .subscribe((res:any) => {
          resolve(res);
        }, (err) =>{
          reject(err);
        });
    });
  }

  mega888reset(lusertoken:string){
    return new Promise((resolve, reject) =>{
      var configUrl = this.url_api + "mega888resetpassword";
      var lbearer = "Bearer " + lusertoken;
      
      let headers= new HttpHeaders({
        'Accept' : 'application/json',
        'Content-Type' : 'application/json',
        'Authorization' : lbearer
      });
      let data = {
        "dummy": "1"
      };
      this.http
        .post(configUrl,JSON.stringify(data),{headers:headers})
        .subscribe((res:any) => {
          resolve(res);
        }, (err) =>{
          reject(err);
        });
    });
  }

  // cash deposit for all
  cashdeposit(lusertoken:string, lbankbame:string, lbankslip:string, lreceipt:string, lbonus:string, lbonusindex:number, lamount:number, lgame: string) {
    return new Promise((resolve, reject) =>{
      var configUrl = this.url_api + "cashdeposit";
      var lbearer = "Bearer " + lusertoken;
      
      let headers= new HttpHeaders({
        'Accept' : 'application/json',
        'Content-Type' : 'application/json',
        'Authorization' : lbearer
      });
      let data = {
        bankname: lbankbame,
        bankslip: lbankslip,
        type: "c",
        bonus: lbonus,
        bonusid: lbonusindex,
        receipt:lreceipt,
        amount: lamount,
        togame: lgame
      };
      this.http
        .post(configUrl,JSON.stringify(data),{headers:headers})
        .subscribe((res:any) => {
          resolve(res);
        }, (err) =>{
          if(err.status==500) {
            this.global.showAlert("Error","User session timeout, please relogin");
            this.navCtrl.navigateForward('/my/en-us/login');
          }
        });
    });
  }

  // pin deposit for all
  pindeposit(lusertoken:string, telco:string, lbankcode:string, lgame: string, lpincode: string) {
    return new Promise((resolve, reject) =>{
      var configUrl = this.url_api + "telcopindeposit";
      var lbearer = "Bearer " + lusertoken;
      
      let headers= new HttpHeaders({
        'Accept' : 'application/json',
        'Content-Type' : 'application/json',
        'Authorization' : lbearer
      });
      let data = {
        telco: telco,
        bankcode: lbankcode,
        togame: lgame,
        pincode: lpincode
      };
      this.http
        .post(configUrl,JSON.stringify(data),{headers:headers})
        .subscribe((res:any) => {
          resolve(res);
        }, (err) =>{
          if(err.status==500) {
            this.global.showAlert("Error","User session timeout, please relogin");
            this.navCtrl.navigateForward('/my/en-us/login');
          }
        });
    });
  }

  checkpindeposit(lusertoken:string, refid:string) {
    return new Promise((resolve, reject) =>{
      var configUrl = this.url_api + "checktelcopindeposit";
      var lbearer = "Bearer " + lusertoken;
      
      let headers= new HttpHeaders({
        'Accept' : 'application/json',
        'Content-Type' : 'application/json',
        'Authorization' : lbearer
      });
      let data = {
        refid: refid
      };
      this.http
        .post(configUrl,JSON.stringify(data),{headers:headers})
        .subscribe((res:any) => {
          resolve(res);
        }, (err) =>{
          if(err.status==500) {
            this.global.showAlert("Error","User session timeout, please relogin");
            this.navCtrl.navigateForward('/my/en-us/login');
          }
        });
    });
  }

  // get the user settings
  usersetting(lusertoken:string){
    return new Promise((resolve, reject) =>{
      var configUrl = this.url_api + "usersetting";
      var lbearer = "Bearer " + lusertoken;
      let headers= new HttpHeaders({
        'Accept' : 'application/json',
        'Content-Type' : 'application/json',
        'Authorization' : lbearer
        //'Customer-Key' : '114057'
      });
      let data = {
        dummy : "1"
      };
      this.http
        .post(configUrl,JSON.stringify(data),{headers:headers})
        .subscribe((res:any) => {
          console.log(res.data.length);
          resolve(res);
        }, (err) =>{
          if(err.status==500) {
            this.global.showAlert("Error","User session timeout, please relogin");
            this.navCtrl.navigateForward('/my/en-us/login');
          }
          //reject(err);
        });
    });
  }

  // get the agent settings
  getagent(lusertoken:string){
    return new Promise((resolve, reject) =>{
      var configUrl = this.url_api + "getagent";
      var lbearer = "Bearer " + lusertoken;
      let headers= new HttpHeaders({
        'Accept' : 'application/json',
        'Content-Type' : 'application/json',
        'Authorization' : lbearer
        //'Customer-Key' : '114057'
      });
      let data = {
        dummy : "1"
      };
      this.http
        .post(configUrl,JSON.stringify(data),{headers:headers})
        .subscribe((res:any) => {
          console.log(res.data.length);
          resolve(res);
        }, (err) =>{
          reject(err);
        });
    });
  }

  gethistory(lusertoken:string, lstartdate:string, lenddate:string){
    return new Promise((resolve, reject) =>{
      var configUrl = this.url_api + "gethistory";
      var lbearer = "Bearer " + lusertoken;
      let headers= new HttpHeaders({
        'Accept' : 'application/json',
        'Content-Type' : 'application/json',
        'Authorization' : lbearer
        //'Customer-Key' : '114057'
      });
      let data = {
        startdate: lstartdate,
        enddate: lenddate
      };
      this.http
        .post(configUrl,JSON.stringify(data),{headers:headers})
        .subscribe((res:any) => {
          console.log(res.data.length);
          resolve(res);
        }, (err) =>{
          if(err.status==500) {
            this.global.showAlert("Error","User session timeout, please relogin");
            this.navCtrl.navigateForward('/my/en-us/login');
          }
        });
    });
  }

  playergames(lusertoken:string){
    return new Promise((resolve, reject) =>{
      var configUrl = this.url_api + "playergames";
      var lbearer = "Bearer " + lusertoken;
      
      let headers= new HttpHeaders({
        'Accept' : 'application/json',
        'Content-Type' : 'application/json',
        'Authorization' : lbearer
      });
      let data = {
        "dummy": "1"
      };
      this.http
        .post(configUrl,JSON.stringify(data),{headers:headers})
        .subscribe((res:any) => {
          resolve(res);
        }, (err) =>{
          reject(err);
        });
    });
  }

  agentgames(lusertoken:string){
    return new Promise((resolve, reject) =>{
      var configUrl = this.url_api + "agentgames";
      var lbearer = "Bearer " + lusertoken;
      
      let headers= new HttpHeaders({
        'Accept' : 'application/json',
        'Content-Type' : 'application/json',
        'Authorization' : lbearer
      });
      let data = {
        "dummy": "1"
      };
      this.http
        .post(configUrl,JSON.stringify(data),{headers:headers})
        .subscribe((res:any) => {
          resolve(res);
        }, (err) =>{
          reject(err);
        });
    });
  }

  agentbonus(lusertoken:string){
    return new Promise((resolve, reject) =>{
      var configUrl = this.url_api + "agentbonus";
      var lbearer = "Bearer " + lusertoken;
      
      let headers= new HttpHeaders({
        'Accept' : 'application/json',
        'Content-Type' : 'application/json',
        'Authorization' : lbearer
      });
      let data = {
        "dummy": "1"
      };
      this.http
        .post(configUrl,JSON.stringify(data),{headers:headers})
        .subscribe((res:any) => {
          resolve(res);
        }, (err) =>{
          reject(err);
        });
    });
  }

  trusted_affiliate(lusertoken:string) {
    return new Promise((resolve, reject) =>{
      var configUrl = this.url_api + "trusted_affiliate";
      var lbearer = "Bearer " + lusertoken;
      
      let headers= new HttpHeaders({
        'Accept' : 'application/json',
        'Content-Type' : 'application/json',
        'Authorization' : lbearer
      });
      let data = {
        "dummy": "1"
      };
      this.http
        .post(configUrl,JSON.stringify(data),{headers:headers})
        .subscribe((res:any) => {
          resolve(res);
        }, (err) =>{
          reject(err);
        });
    });
  }

  chkaffiliate(laffid:string){
    return new Promise((resolve, reject) =>{
      var configUrl = this.url_api + "chkaffiliate";
      
      let headers= new HttpHeaders({
        'Accept' : 'application/json',
        'Content-Type' : 'application/json'
      });
      let data = {
        "affid": laffid
      };
      this.http
        .post(configUrl,JSON.stringify(data),{headers:headers})
        .subscribe((res:any) => {
          resolve(res);
        }, (err) =>{
          reject(err);
        });
    });
  }

  deleteplayer(lusertoken:string, lgametype:string) {
    return new Promise((resolve, reject) =>{
      var configUrl = this.url_api + "deleteplayer";
      var lbearer = "Bearer " + lusertoken;
      
      let headers= new HttpHeaders({
        'Accept' : 'application/json',
        'Content-Type' : 'application/json',
        'Authorization' : lbearer
      });
      let data = {
        "gametype": lgametype
      };
      this.http
        .post(configUrl,JSON.stringify(data),{headers:headers})
        .subscribe((res:any) => {
          resolve(res);
        }, (err) =>{
          if(err.status==500) {
            this.global.showAlert("Error","User session timeout, please relogin");
            this.navCtrl.navigateForward('/my/en-us/login');
          }
        });
    });
  }
  
  createaffwithdrawal(lusertoken:string, lbankacc_id:string,lamount:string){
    return new Promise((resolve, reject) =>{
      var configUrl = this.url_api + "createaffwithdrawal";
      var lbearer = "Bearer " + lusertoken;
      let headers= new HttpHeaders({
        'Accept' : 'application/json',
        'Content-Type' : 'application/json',
        'Authorization' : lbearer
        //'Customer-Key' : '114057'
      });
      let data = {
        bankacc_id: lbankacc_id,
        amount: lamount
      };
      this.http
        .post(configUrl,JSON.stringify(data),{headers:headers})
        .subscribe((res:any) => {
          //console.log(res.data.length);
          resolve(res);
        }, (err) =>{
          if(err.status==500) {
            this.global.showAlert("Error","User session timeout, please relogin");
            this.navCtrl.navigateForward('/my/my/en-us/login');
          }
        });
    });
  }

  viewaffwithdrawal(lusertoken:string, lstartdate:string, lenddate:string){
    return new Promise((resolve, reject) =>{
      var configUrl = this.url_api + "viewaffwithdrawal";
      var lbearer = "Bearer " + lusertoken;
      let headers= new HttpHeaders({
        'Accept' : 'application/json',
        'Content-Type' : 'application/json',
        'Authorization' : lbearer
        //'Customer-Key' : '114057'
      });
      let data = {
        startdate: lstartdate,
        enddate: lenddate
      };
      this.http
        .post(configUrl,JSON.stringify(data),{headers:headers})
        .subscribe((res:any) => {
          console.log(res.data.length);
          resolve(res);
        }, (err) =>{
          if(err.status==500) {
            this.global.showAlert("Error","User session timeout, please relogin");
            this.navCtrl.navigateForward('/my/my/en-us/login');
          }
        });
    });
  }

  // joker APIs
  jokerplayerinfo(lusertoken:string){
    return new Promise((resolve, reject) =>{
      var configUrl = this.url_api + "jokerplayerinfo";
      var lbearer = "Bearer " + lusertoken;
      
      let headers= new HttpHeaders({
        'Accept' : 'application/json',
        'Content-Type' : 'application/json',
        'Authorization' : lbearer
      });
      let data = {
        dummy: "1"
      };
      this.http
        .post(configUrl,JSON.stringify(data),{headers:headers})
        .subscribe((res:any) => {
          resolve(res);
        }, (err) =>{
          reject(err);
        });
    });
  }
      
  // joker APIs
  jokergettoken(lusertoken:string){
    return new Promise((resolve, reject) =>{
      var configUrl = this.url_api + "jokergettoken";
      var lbearer = "Bearer " + lusertoken;
      
      let headers= new HttpHeaders({
        'Accept' : 'application/json',
        'Content-Type' : 'application/json',
        'Authorization' : lbearer
      });
      let data = {
        dummy: "1"
      };
      this.http
        .post(configUrl,JSON.stringify(data),{headers:headers})
        .subscribe((res:any) => {
          resolve(res);
        }, (err) =>{
          reject(err);
        });
    });
  }

  // joker APIs
  jokercreate(lusertoken:string) {
    return new Promise((resolve, reject) =>{
      var configUrl = this.url_api + "jokercreate";
      var lbearer = "Bearer " + lusertoken;
      
      let headers= new HttpHeaders({
        'Accept' : 'application/json',
        'Content-Type' : 'application/json',
        'Authorization' : lbearer
      });
      let data = {
        dummy: "1"
      };
      this.http
        .post(configUrl,JSON.stringify(data),{headers:headers})
        .subscribe((res:any) => {
          resolve(res);
        }, (err) =>{
          reject(err);
        });
    });
  }

  jokercredit(lusertoken:string, lamt:string){
    return new Promise((resolve, reject) =>{
      var configUrl = this.url_api + "jokercredit";
      var lbearer = "Bearer " + lusertoken;

      let headers= new HttpHeaders({
        'Accept' : 'application/json',
        'Content-Type' : 'application/json',
        'Authorization' : lbearer
      });
      let data = {
          amount : lamt
      };
      this.http
          .post(configUrl,JSON.stringify(data),{headers:headers})
          .subscribe((res:any) => {
            resolve(res);
          }, (err) =>{
            reject(err);
          });
      });
  }

  jokerreset(lusertoken:string){
    return new Promise((resolve, reject) =>{
      var configUrl = this.url_api + "jokerresetpassword";
      var lbearer = "Bearer " + lusertoken;
      
      let headers= new HttpHeaders({
        'Accept' : 'application/json',
        'Content-Type' : 'application/json',
        'Authorization' : lbearer
      });
      let data = {
        "dummy": "1"
      };
      this.http
        .post(configUrl,JSON.stringify(data),{headers:headers})
        .subscribe((res:any) => {
          resolve(res);
        }, (err) =>{
          reject(err);
        });
    });
  }

  jokergamelist(lusertoken:string){
    return new Promise((resolve, reject) =>{
      var configUrl = this.url_api + "jokergamelist";
      var lbearer = "Bearer " + lusertoken;
      let headers= new HttpHeaders({
        'Accept' : 'application/json',
        'Content-Type' : 'application/json',
        'Authorization' : lbearer
      });
      let data = {
        dummy : 1
      };
      //console.log("add bank data is " + lbankname + "|" + laccno + "|" + laccname);
      this.http
        .post(configUrl,JSON.stringify(data),{headers:headers})
        .subscribe((res:any) => {
          //console.log(res.length);
          resolve(res);
        }, (err) =>{
          if(err.status==500) {
            this.global.showAlert("Error","User session timeout, please relogin");
            this.navCtrl.navigateForward('/login');
          }
        });
    });
  }

  getmodules(lmodule:string, llanguage:string) {
    return new Promise((resolve, reject) =>{
      var configUrl = this.url_api + "getmodules";
      let headers= new HttpHeaders({
        'Accept' : 'application/json',
        'Content-Type' : 'application/json'
      });
      let data = {
        module_name : lmodule,
        language: llanguage,
        app_secret: this.global.app_secret
      };
      this.http
        .post(configUrl,JSON.stringify(data),{headers:headers})
        .subscribe((res:any) => {
          console.log(res.data.length);
          resolve(res);
        }, (err) =>{
          reject(err);
        });
    });
  }

  // regcredit APIs
  regcredit(lusertoken:string){
    return new Promise((resolve, reject) =>{
      var configUrl = this.url_api + "regcredit";
      var lbearer = "Bearer " + lusertoken;

      let headers= new HttpHeaders({
        'Accept' : 'application/json',
        'Content-Type' : 'application/json',
        'Authorization' : lbearer
      });
      let data = {
        dummy: "1"
      };
      this.http
        .post(configUrl,JSON.stringify(data),{headers:headers})
        .subscribe((res:any) => {
          resolve(res);
        }, (err) =>{
          reject(err);
        });
    });
  }

  // ace333 APIs
  ace333create(lusertoken:string) {
    return new Promise((resolve, reject) =>{
      var configUrl = this.url_api + "ace333create";
      var lbearer = "Bearer " + lusertoken;
      
      let headers= new HttpHeaders({
        'Accept' : 'application/json',
        'Content-Type' : 'application/json',
        'Authorization' : lbearer
      });
      let data = {
        dummy: "1"
      };
      this.http
        .post(configUrl,JSON.stringify(data),{headers:headers})
        .subscribe((res:any) => {
          resolve(res);
        }, (err) =>{
          reject(err);
        });
    });
  }

  ace333credit(lusertoken:string, lamt:string, lrefid:string){
    return new Promise((resolve, reject) =>{
      var configUrl = this.url_api + "ace333credit";
      var lbearer = "Bearer " + lusertoken;

      let headers= new HttpHeaders({
        'Accept' : 'application/json',
        'Content-Type' : 'application/json',
        'Authorization' : lbearer
      });
      let data = {
          amount : lamt,
          referenceID : lrefid
      };
      this.http
          .post(configUrl,JSON.stringify(data),{headers:headers})
          .subscribe((res:any) => {
            resolve(res);
          }, (err) =>{
            reject(err);
          });
      });
  }

  ace333reset(lusertoken:string){
    return new Promise((resolve, reject) =>{
      var configUrl = this.url_api + "ace333resetpassword";
      var lbearer = "Bearer " + lusertoken;
      
      let headers= new HttpHeaders({
        'Accept' : 'application/json',
        'Content-Type' : 'application/json',
        'Authorization' : lbearer
      });
      let data = {
        "dummy": "1"
      };
      this.http
        .post(configUrl,JSON.stringify(data),{headers:headers})
        .subscribe((res:any) => {
          resolve(res);
        }, (err) =>{
          reject(err);
        });
    });
  }

  // xe88 APIs
  ace333playerinfo(lusertoken:string){
    return new Promise((resolve, reject) =>{
      var configUrl = this.url_api + "ace333playerinfo";
      var lbearer = "Bearer " + lusertoken;
      
      let headers= new HttpHeaders({
        'Accept' : 'application/json',
        'Content-Type' : 'application/json',
        'Authorization' : lbearer
      });
      let data = {
        dummy: "1"
      };
      this.http
        .post(configUrl,JSON.stringify(data),{headers:headers})
        .subscribe((res:any) => {
          resolve(res);
        }, (err) =>{
          reject(err);
        });
    });
  }

  rebateview(lusertoken:string){
    return new Promise((resolve, reject) =>{
      var configUrl = this.url_api + "rebateview";
      var lbearer = "Bearer " + lusertoken;
      let headers= new HttpHeaders({
        'Accept' : 'application/json',
        'Content-Type' : 'application/json',
        'Authorization' : lbearer
      });
      let data = {
        dummy : "1"
      };
      this.http
        .post(configUrl,JSON.stringify(data),{headers:headers})
        .subscribe((res:any) => {
          resolve(res);
        }, (err) =>{
          if(err.status==500) {
            this.global.showAlert("Error","User session timeout, please relogin");
            this.navCtrl.navigateForward('/login');
          }
        });
    });
  }
  
  rebateupdate(lusertoken:string, lgame:string) {
    return new Promise((resolve, reject) =>{
      var configUrl = this.url_api + "rebateupdate";
      var lbearer = "Bearer " + lusertoken;
      let headers= new HttpHeaders({
        'Accept' : 'application/json',
        'Content-Type' : 'application/json',
        'Authorization' : lbearer
      });
      let data = {
        game : lgame
      };
      this.http
        .post(configUrl,JSON.stringify(data),{headers:headers})
        .subscribe((res:any) => {
          resolve(res);
        }, (err) =>{
          if(err.status==500) {
            this.global.showAlert("Error","User session timeout, please relogin");
            this.navCtrl.navigateForward('/login');
          }
        });
    });
  }

  vaderpay(lusertoken:string, lamount:number, lbonusid:number, ltogame:string, lrefid:string, lmerchant, lcurrency:string, lposturl:string, lreturnurl:string, lfailedreturnurl:string ){
    return new Promise((resolve, reject) =>{
      var configUrl = this.url_api + "vaderpay";
      var lbearer = "Bearer " + lusertoken;
      let headers= new HttpHeaders({
        'Accept' : 'application/json',
        'Content-Type' : 'application/json',
        'Authorization' : lbearer
      });
      let data = {
        amount : lamount,
        bonusid : lbonusid,
        togame : ltogame,
        refid : lrefid,
        merchant : lmerchant,
        currency : lcurrency,
        posturl : lposturl,
        returnurl : lreturnurl,
        failedreturnurl : lfailedreturnurl
      };
      console.log("vaderpay  data is " + lamount + "|" + ltogame + "|" + lmerchant);
      this.http
        .post(configUrl,JSON.stringify(data),{headers:headers})
        .subscribe((res:any) => {
          console.log(res.length);
          resolve(res);
        }, (err) =>{
          if(err.status==500) {
            this.global.showAlert("Error","User session timeout, please relogin");
            this.navCtrl.navigateForward('/login');
          }
        });
    });
  }

  // 918kaya APIs
  kaya918create(lusertoken:string) {
    return new Promise((resolve, reject) =>{
      var configUrl = this.url_api + "Kaya918create";
      var lbearer = "Bearer " + lusertoken;
      
      let headers= new HttpHeaders({
        'Accept' : 'application/json',
        'Content-Type' : 'application/json',
        'Authorization' : lbearer
      });
      let data = {
        dummy: "1"
      };
      this.http
        .post(configUrl,JSON.stringify(data),{headers:headers})
        .subscribe((res:any) => {
          resolve(res);
        }, (err) =>{
          reject(err);
        });
    });
  }

  kaya918playerinfo(lusertoken:string){
    return new Promise((resolve, reject) =>{
      var configUrl = this.url_api + "Kaya918playerinfo";
      var lbearer = "Bearer " + lusertoken;
      
      let headers= new HttpHeaders({
        'Accept' : 'application/json',
        'Content-Type' : 'application/json',
        'Authorization' : lbearer
      });
      let data = {
        dummy: "1"
      };
      this.http
        .post(configUrl,JSON.stringify(data),{headers:headers})
        .subscribe((res:any) => {
          resolve(res);
        }, (err) =>{
          reject(err);
        });
    });
  }

  kaya918credit(lusertoken:string, lamt:string){
    return new Promise((resolve, reject) =>{
      var configUrl = this.url_api + "Kaya918credit";
      var lbearer = "Bearer " + lusertoken;
      
      let headers= new HttpHeaders({
        'Accept' : 'application/json',
        'Content-Type' : 'application/json',
        'Authorization' : lbearer
      });
      let data = {
        amt: lamt
      };
      this.http
        .post(configUrl,JSON.stringify(data),{headers:headers})
        .subscribe((res:any) => {
          resolve(res);
        }, (err) =>{
          reject(err);
        });
    });
  }

  kaya918reset(lusertoken:string){
    return new Promise((resolve, reject) =>{
      var configUrl = this.url_api + "Kaya918reset";
      var lbearer = "Bearer " + lusertoken;
      
      let headers= new HttpHeaders({
        'Accept' : 'application/json',
        'Content-Type' : 'application/json',
        'Authorization' : lbearer
      });
      let data = {
        "dummy": "1"
      };
      this.http
        .post(configUrl,JSON.stringify(data),{headers:headers})
        .subscribe((res:any) => {
          resolve(res);
        }, (err) =>{
          reject(err);
        });
    });
  }
  
  // playtech APIs
  playtechplayerinfo(lusertoken:string){
    return new Promise((resolve, reject) =>{
      var configUrl = this.url_api + "PTplayerinfo";
      var lbearer = "Bearer " + lusertoken;
      
      let headers= new HttpHeaders({
        'Accept' : 'application/json',
        'Content-Type' : 'application/json',
        'Authorization' : lbearer
      });
      let data = {
        dummy: "1"
      };
      this.http
        .post(configUrl,JSON.stringify(data),{headers:headers})
        .subscribe((res:any) => {
          resolve(res);
        }, (err) =>{
          reject(err);
        });
    });
  
  }

  playtechreset(lusertoken:string){
    return new Promise((resolve, reject) =>{
      var configUrl = this.url_api + "PTresetpassword";
      var lbearer = "Bearer " + lusertoken;
      
      let headers= new HttpHeaders({
        'Accept' : 'application/json',
        'Content-Type' : 'application/json',
        'Authorization' : lbearer
      });
      let data = {
        "dummy": "1"
      };
      this.http
        .post(configUrl,JSON.stringify(data),{headers:headers})
        .subscribe((res:any) => {
          resolve(res);
        }, (err) =>{
          reject(err);
        });
    });
  }

  playtechcreate(lusertoken:string) {
    return new Promise((resolve, reject) =>{
      var configUrl = this.url_api + "PTcreate";
      var lbearer = "Bearer " + lusertoken;
      
      let headers= new HttpHeaders({
        'Accept' : 'application/json',
        'Content-Type' : 'application/json',
        'Authorization' : lbearer
      });
      let data = {
        dummy: "1"
      };
      this.http
        .post(configUrl,JSON.stringify(data),{headers:headers})
        .subscribe((res:any) => {
          resolve(res);
        }, (err) =>{
          reject(err);
        });
    });
  }

  playtechcredit(lusertoken:string, lamt:string) {
    return new Promise((resolve, reject) =>{
      var configUrl = this.url_api + "PTplayercredit";
      var lbearer = "Bearer " + lusertoken;
      
      let headers= new HttpHeaders({
        'Accept' : 'application/json',
        'Content-Type' : 'application/json',
        'Authorization' : lbearer
      });
      let data = {
        amount : lamt
      };
      this.http
        .post(configUrl,JSON.stringify(data),{headers:headers})
        .subscribe((res:any) => {
          resolve(res);
        }, (err) =>{
          reject(err);
        });
    });
  }

  // evo888 APIs
  evo888playerinfo(lusertoken:string){
    return new Promise((resolve, reject) =>{
      var configUrl = this.url_api + "evo888playerinfo";
      var lbearer = "Bearer " + lusertoken;
      
      let headers= new HttpHeaders({
        'Accept' : 'application/json',
        'Content-Type' : 'application/json',
        'Authorization' : lbearer
      });
      let data = {
        dummy: "1"
      };
      this.http
        .post(configUrl,JSON.stringify(data),{headers:headers})
        .subscribe((res:any) => {
          resolve(res);
        }, (err) =>{
          console.log("Error in evo888playerinfo");
          console.log(err);
          reject(err);
        });
    });
  
  }

  evo888credit(lusertoken:string, lamt:string) {
    return new Promise((resolve, reject) =>{
      var configUrl = this.url_api + "evo888credit";
      var lbearer = "Bearer " + lusertoken;
      
      let headers= new HttpHeaders({
        'Accept' : 'application/json',
        'Content-Type' : 'application/json',
        'Authorization' : lbearer
      });
      let data = {
        amount : lamt
      };
      this.http
        .post(configUrl,JSON.stringify(data),{headers:headers})
        .subscribe((res:any) => {
          resolve(res);
        }, (err) =>{
          reject(err);
        });
    });
  
  }

  evo888reset(lusertoken:string){
    return new Promise((resolve, reject) =>{
      var configUrl = this.url_api + "evo888resetpassword";
      var lbearer = "Bearer " + lusertoken;
      
      let headers= new HttpHeaders({
        'Accept' : 'application/json',
        'Content-Type' : 'application/json',
        'Authorization' : lbearer
      });
      let data = {
        "dummy": "1"
      };
      this.http
        .post(configUrl,JSON.stringify(data),{headers:headers})
        .subscribe((res:any) => {
          resolve(res);
        }, (err) =>{
          reject(err);
        });
    });
  }

  // evo888 APIs
  evo888create(lusertoken:string) {
    return new Promise((resolve, reject) =>{
      var configUrl = this.url_api + "evo888create";
      var lbearer = "Bearer " + lusertoken;
      
      let headers= new HttpHeaders({
        'Accept' : 'application/json',
        'Content-Type' : 'application/json',
        'Authorization' : lbearer
      });
      let data = {
        dummy: "1"
      };
      this.http
        .post(configUrl,JSON.stringify(data),{headers:headers})
        .subscribe((res:any) => {
          resolve(res);
        }, (err) =>{
          reject(err);
        });
    });
  }

  // h5kiss APIs
  h5kissgettoken(lusertoken:string){
    return new Promise((resolve, reject) =>{
      var configUrl = this.url_api + "h5kissgettoken";
      var lbearer = "Bearer " + lusertoken;
      
      let headers= new HttpHeaders({
        'Accept' : 'application/json',
        'Content-Type' : 'application/json',
        'Authorization' : lbearer
      });
      let data = {
        dummy: "1"
      };
      this.http
        .post(configUrl,JSON.stringify(data),{headers:headers})
        .subscribe((res:any) => {
          resolve(res);
        }, (err) =>{
          reject(err);
        });
    });
  }
  
  // h5kiss APIs
  h5kissplayerinfo(lusertoken:string){
    return new Promise((resolve, reject) =>{
      var configUrl = this.url_api + "h5kissplayerinfo";
      var lbearer = "Bearer " + lusertoken;
      
      let headers= new HttpHeaders({
        'Accept' : 'application/json',
        'Content-Type' : 'application/json',
        'Authorization' : lbearer
      });
      let data = {
        dummy: "1"
      };
      this.http
        .post(configUrl,JSON.stringify(data),{headers:headers})
        .subscribe((res:any) => {
          resolve(res);
        }, (err) =>{
          reject(err);
        });
    });
  }

  // h5kiss APIs
  h5kisscreate(lusertoken:string) {
    return new Promise((resolve, reject) =>{
      var configUrl = this.url_api + "h5kisscreate";
      var lbearer = "Bearer " + lusertoken;
      
      let headers= new HttpHeaders({
        'Accept' : 'application/json',
        'Content-Type' : 'application/json',
        'Authorization' : lbearer
      });
      let data = {
        dummy: "1"
      };
      this.http
        .post(configUrl,JSON.stringify(data),{headers:headers})
        .subscribe((res:any) => {
          resolve(res);
        }, (err) =>{
          reject(err);
        });
    });
  }
  
  h5kisscredit(lusertoken:string, lamt:string){
    return new Promise((resolve, reject) =>{
      var configUrl = this.url_api + "h5kisscredit";
      var lbearer = "Bearer " + lusertoken;
      
      let headers= new HttpHeaders({
        'Accept' : 'application/json',
        'Content-Type' : 'application/json',
        'Authorization' : lbearer
      });
      let data = {
        amount : lamt
      };
      this.http
        .post(configUrl,JSON.stringify(data),{headers:headers})
        .subscribe((res:any) => {
          resolve(res);
        }, (err) =>{
          reject(err);
        });
    });
  
  }
    
  h5kissreset(lusertoken:string){
    return new Promise((resolve, reject) =>{
      var configUrl = this.url_api + "h5kissresetpassword";
      var lbearer = "Bearer " + lusertoken;
      
      let headers= new HttpHeaders({
        'Accept' : 'application/json',
        'Content-Type' : 'application/json',
        'Authorization' : lbearer
      });
      let data = {
        "dummy": "1"
      };
      this.http
        .post(configUrl,JSON.stringify(data),{headers:headers})
        .subscribe((res:any) => {
          resolve(res);
        }, (err) =>{
          reject(err);
        });
    });
  }

  onlinedeposit(lusertoken:string, lbankcode:string, lamount:number, lbonusid:number, ltogame:string){
    return new Promise((resolve, reject) =>{
      var configUrl = this.url_api + "onlinedeposit";
      var lbearer = "Bearer " + lusertoken;
      let headers= new HttpHeaders({
        'Accept' : 'application/json',
        'Content-Type' : 'application/json',
        'Authorization' : lbearer
      });
      let data = {
        bankcode : lbankcode,
        amount : lamount,
        bonusid : lbonusid,
        togame : ltogame
      };
      //console.log("add bank data is " + lbankname + "|" + laccno + "|" + laccname);
      this.http
        .post(configUrl,JSON.stringify(data),{headers:headers})
        .subscribe((res:any) => {
          console.log(res.length);
          resolve(res);
        }, (err) =>{
          if(err.status==500) {
            this.global.showAlert("Error","User session timeout, please relogin");
            this.navCtrl.navigateForward('/login');
          }
        });
    });
  }

  getchannel(){
    return new Promise((resolve, reject) =>{
      var configUrl = this.url_api + "playerregchannels";
      // var configUrl = "http://v2host/api/playerregchannels";
      // var lbearer = "Bearer " + lusertoken;
      
      let headers= new HttpHeaders({
        'Accept' : 'application/json',
        'Content-Type' : 'application/json',
        // 'Authorization' : lbearer
      });
      let data = {
        app_secret : this.global.app_secret
      };
      this.http
        .post(configUrl,JSON.stringify(data),{headers:headers})
        .subscribe((res:any) => {
          resolve(res);
        }, (err) =>{
          reject(err);
        });
    });
  }

  chkaffplayer(lusertoken:string, lmobileno:string) {
    return new Promise((resolve, reject) =>{
      var configUrl = this.url_api + "chkaffplayer";
      var lbearer = "Bearer " + lusertoken;
  
      let headers= new HttpHeaders({
        'Accept' : 'application/json',
        'Content-Type' : 'application/json',
        'Authorization' : lbearer
      });
      let data = {
        "mobileno": lmobileno
      };
      this.http
        .post(configUrl,JSON.stringify(data),{headers:headers})
        .subscribe((res:any) => {
          resolve(res);
        }, (err) =>{
          reject(err);
        });
    });
  }

  // pussy888 APIs
  pussy888create(lusertoken:string) {
    return new Promise((resolve, reject) =>{
      var configUrl = this.url_api + "pussy888create";
      var lbearer = "Bearer " + lusertoken;
      
      let headers= new HttpHeaders({
        'Accept' : 'application/json',
        'Content-Type' : 'application/json',
        'Authorization' : lbearer
      });
      let data = {
        dummy: "1"
      };
      this.http
        .post(configUrl,JSON.stringify(data),{headers:headers})
        .subscribe((res:any) => {
          resolve(res);
        }, (err) =>{
          reject(err);
        });
    });
  
  }

  pussy888credit(lusertoken:string, lamt:string){
    return new Promise((resolve, reject) =>{
      var configUrl = this.url_api + "pussy888credit";
      var lbearer = "Bearer " + lusertoken;
      
      let headers= new HttpHeaders({
        'Accept' : 'application/json',
        'Content-Type' : 'application/json',
        'Authorization' : lbearer
      });
      let data = {
        amt : lamt
      };
      this.http
        .post(configUrl,JSON.stringify(data),{headers:headers})
        .subscribe((res:any) => {
          resolve(res);
        }, (err) =>{
          reject(err);
        });
    });
  
  }

  pussy888reset(lusertoken:string){
    return new Promise((resolve, reject) =>{
      var configUrl = this.url_api + "pussy888reset";
      var lbearer = "Bearer " + lusertoken;
      
      let headers= new HttpHeaders({
        'Accept' : 'application/json',
        'Content-Type' : 'application/json',
        'Authorization' : lbearer
      });
      let data = {
        "dummy": "1"
      };
      this.http
        .post(configUrl,JSON.stringify(data),{headers:headers})
        .subscribe((res:any) => {
          resolve(res);
        }, (err) =>{
          reject(err);
        });
    });
  }

  pussy888playerinfo(lusertoken:string){
    return new Promise((resolve, reject) =>{
      var configUrl = this.url_api + "pussy888playerinfo";
      var lbearer = "Bearer " + lusertoken;
      
      let headers= new HttpHeaders({
        'Accept' : 'application/json',
        'Content-Type' : 'application/json',
        'Authorization' : lbearer
      });
      let data = {
        dummy: "1"
      };
      this.http
        .post(configUrl,JSON.stringify(data),{headers:headers})
        .subscribe((res:any) => {
          resolve(res);
        }, (err) =>{
          reject(err);
        });
    });
  }

  pwasignup(lusertoken:string) {
    return new Promise((resolve, reject) =>{
      var configUrl = this.url_api + "pwasignup";
      var lbearer = "Bearer " + lusertoken;
  
      let headers= new HttpHeaders({
        'Accept' : 'application/json',
        'Content-Type' : 'application/json',
        'Authorization' : lbearer
      });
      let data = {
        "dummy": "1"
      };
      this.http
        .post(configUrl,JSON.stringify(data),{headers:headers})
        .subscribe((res:any) => {
          resolve(res);
        }, (err) =>{
          reject(err);
        });
    });
  }

  notificationsignup(lusertoken:string, token:string) {
    return new Promise((resolve, reject) =>{
      var configUrl = this.url_api + "notificationsignup";
      var lbearer = "Bearer " + lusertoken;
  
      let headers= new HttpHeaders({
        'Accept' : 'application/json',
        'Content-Type' : 'application/json',
        'Authorization' : lbearer
      });
      let data = {
        "token": token
      };
      this.http
        .post(configUrl,JSON.stringify(data),{headers:headers})
        .subscribe((res:any) => {
          resolve(res);
        }, (err) =>{
          reject(err);
        });
    });
  }

  notificationremove(lusertoken:string) {
    return new Promise((resolve, reject) =>{
      var configUrl = this.url_api + "notificationremove";
      var lbearer = "Bearer " + lusertoken;
  
      let headers= new HttpHeaders({
        'Accept' : 'application/json',
        'Content-Type' : 'application/json',
        'Authorization' : lbearer
      });
      let data = {
        "dummy": "1"
      };
      this.http
        .post(configUrl,JSON.stringify(data),{headers:headers})
        .subscribe((res:any) => {
          resolve(res);
        }, (err) =>{
          reject(err);
        });
    });
  }

  notificationupdate(lusertoken:string, myValuePR:number, myValueAN:number) {
    return new Promise((resolve, reject) =>{
      var configUrl = this.url_api + "notificationupdate";
      var lbearer = "Bearer " + lusertoken;
  
      let headers= new HttpHeaders({
        'Accept' : 'application/json',
        'Content-Type' : 'application/json',
        'Authorization' : lbearer
      });
      let data = {
        "myValuePR": myValuePR,
        "myValueAN" : myValueAN
      };
      this.http
        .post(configUrl,JSON.stringify(data),{headers:headers})
        .subscribe((res:any) => {
          resolve(res);
        }, (err) =>{
          reject(err);
        });
    });
  }
}
