import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { ModalPage } from './modal/modal.page';

const routes: Routes = [


  // {
  //   path: '',
  //   loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)
  // },
  // {
  //   path: 'home',
  //   loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)
  // },
  { path: '', redirectTo: 'my/en-us/home', pathMatch: 'full' },
  {
    path: 'my/en-us/home',
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)
  },
  {
    path: 'en-2',
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)
  },
  {
    path: 'my/en-us',
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)
  },
  {
    path: 'my/en-us/download',
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)
  },
  {
    path: 'my/download',
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)
  },
  {
    path: 'download-2',
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)
  },
  {
    path: 'spin-win-2',
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)
  },
  {
    path: 'bonus',
    loadChildren: () => import('./bonus/bonus.module').then( m => m.BonusPageModule)
  },
  {
    path: 'changepassword',
    loadChildren: () => import('./account/changepassword/changepassword.module').then( m => m.ChangepasswordPageModule)
  },
  {
    path: 'editprofile',
    loadChildren: () => import('./account/editprofile/editprofile.module').then( m => m.EditprofilePageModule)
  },
  {
    path: 'forgetpassword',
    loadChildren: () => import('./account/forgetpassword/forgetpassword.module').then( m => m.ForgetpasswordPageModule)
  },
  {
    path: 'password-reset',
    loadChildren: () => import('./account/forgetpassword/forgetpassword.module').then( m => m.ForgetpasswordPageModule)
  },
  {
    path: 'en-us/livechat',
    loadChildren: () => import('./account/livechat/livechat.module').then( m => m.LivechatPageModule)
  },
  {
    path: 'notification',
    loadChildren: () => import('./account/notification/notification.module').then( m => m.NotificationPageModule)
  },
  {
    path: 'account',
    loadChildren: () => import('./account/account.module').then( m => m.AccountPageModule)
  },
  {
    path: 'my/en-us/promotion',
    loadChildren: () => import('./bonus/bonus.module').then( m => m.BonusPageModule)
  },
  {
    path: 'my/en-us/login',
    loadChildren: () => import('./auth/login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'my/en-us/register',
    loadChildren: () => import('./auth/signup/signup.module').then( m => m.SignupPageModule)
  },
  {
    path: 'register-en',
    loadChildren: () => import('./auth/signup/signup.module').then( m => m.SignupPageModule)
  },
  {
    path: 'my/register',
    loadChildren: () => import('./auth/signup/signup.module').then( m => m.SignupPageModule)
  },
  {
    path: 'my/en-us/register/',
    loadChildren: () => import('./auth/signup/signup.module').then( m => m.SignupPageModule)
  },
  {
    path: 'signup/:id',
    loadChildren: () => import('./auth/signup/signup.module').then( m => m.SignupPageModule)
  },
  {
    path: 'addbank',
    loadChildren: () => import('./addbank/addbank.module').then( m => m.AddbankPageModule)
  },
  {
    path: 'commision-withdrawal',
    loadChildren: () => import('./affiliate/commision-withdrawal/commision-withdrawal.module').then( m => m.CommisionWithdrawalPageModule)
  },

  {
    path: 'mostactive',
    loadChildren: () => import('./affiliate/mostactive/mostactive.module').then( m => m.MostactivePageModule)
  },
  {
    path: 'profile',
    loadChildren: () => import('./affiliate/profile/profile.module').then( m => m.ProfilePageModule)
  },
  {
    path: 'viewaffwithdrawal',
    loadChildren: () => import('./affiliate/viewaffwithdrawal/viewaffwithdrawal.module').then( m => m.ViewaffwithdrawalPageModule)
  },
  {
    path: 'balance-transfer',
    loadChildren: () => import('./balance-transfer/balance-transfer.module').then( m => m.BalanceTransferPageModule)
  },
  {
    path: 'deposit',
    loadChildren: () => import('./deposit/deposit.module').then( m => m.DepositPageModule)
  },

  {
    path: 'profiles',
    loadChildren: () => import('./games/profiles/profiles.module').then( m => m.ProfilesPageModule)
  },
  {
    path: 'history',
    loadChildren: () => import('./history/history.module').then( m => m.HistoryPageModule)
  },
  {
    path: 'inbox',
    loadChildren: () => import('./inbox/inbox.module').then( m => m.InboxPageModule)
  },
  {
    path: 'jokergamelist',
    loadChildren: () => import('./jokergamelist/jokergamelist.module').then( m => m.JokergamelistPageModule)
  },

  {
    path: 'maintenance',
    loadChildren: () => import('./maintenance/maintenance.module').then( m => m.MaintenancePageModule)
  },

  {
    path: 'minigame',
    loadChildren: () => import('./minigame/minigame.module').then( m => m.MinigamePageModule)
  },

  {
    path: 'angpow',
    loadChildren: () => import('./minigame/angpow/angpow.module').then( m => m.AngpowPageModule)
  },

  {
    path: 'playtechgamelist',
    loadChildren: () => import('./playtechgamelist/playtechgamelist.module').then( m => m.PlaytechgamelistPageModule)
  },

  {
    path: 'weeklyrebate',
    loadChildren: () => import('./weeklyrebate/weeklyrebate.module').then( m => m.WeeklyrebatePageModule)
  },

  {
    path: 'winner-anouncement',
    loadChildren: () => import('./winner-anouncement/winner-anouncement.module').then( m => m.WinnerAnouncementPageModule)
  },
  {
    path: 'my/en-us/slot',
    loadChildren: () => import('./slot/slot.module').then( m => m.SlotPageModule)
  },


  //{ path: 'my/en-us/promotion', loadChildren: './bonus/bonus.module#BonusPageModule' },


  //{ path: '', redirectTo: 'my/en-us/home', pathMatch: 'full' },
  
  //{ path: 'auth', loadChildren: './auth/auth.module#AuthPageModule'},
  //{ path: 'my/en-us/login', loadChildren: './auth/login/login.module#LoginPageModule'},
  //{ path: 'my/en-us/register', loadChildren: './auth/signup/signup.module#SignupPageModule' },
  //{ path: 'my/register', loadChildren: './auth/signup/signup.module#SignupPageModule' },
  //{ path: 'signup/:id', loadChildren: './auth/signup/signup.module#SignupPageModule' },
  //{ path: 'account', loadChildren: './account/account.module#AccountPageModule' },
  //{ path: 'editprofile', loadChildren: './account/editprofile/editprofile.module#EditprofilePageModule' },
  //{ path: 'changepassword', loadChildren: './account/changepassword/changepassword.module#ChangepasswordPageModule' },
  //{ path: 'forgetpassword', loadChildren: './account/forgetpassword/forgetpassword.module#ForgetpasswordPageModule' },
  //{ path: 'en-us/livechat', loadChildren: './account/livechat/livechat.module#LivechatPageModule' },
  //{ path: 'games', loadChildren: './games/games.module#GamesPageModule' },
  //{ path: 'profiles', loadChildren: './games/profiles/profiles.module#ProfilesPageModule' },
  //{ path: 'notification', loadChildren: './account/notification/notification.module#NotificationPageModule' },
  //{ path: 'inbox', loadChildren: './inbox/inbox.module#InboxPageModule' },
  //{ path: 'my/en-us/promotion', loadChildren: './bonus/bonus.module#BonusPageModule' },
  //{ path: 'my/en-us/bonus', loadChildren: './bonus/bonus.module#BonusPageModule' },
  //{ path: 'deposit', loadChildren: './deposit/deposit.module#DepositPageModule' },
  //{ path: 'payment', loadChildren: './deposit/payment/payment.module#PaymentPageModule' },
  //{ path: 'card', loadChildren: './deposit/card/card.module#CardPageModule' },
  //{ path: 'minigame', loadChildren: './minigame/minigame.module#MinigamePageModule' },
  //{ path: 'winner-anouncement', loadChildren: './winner-anouncement/winner-anouncement.module#WinnerAnouncementPageModule' },
  //{ path: 'withdrawal', loadChildren: './withdrawal/withdrawal.module#WithdrawalPageModule' },
  {
    path: 'withdrawal',
    loadChildren: () => import('./withdrawal/withdrawal.module').then( m => m.WithdrawalPageModule)
  },
  //{ path: 'addbank', loadChildren: './addbank/addbank.module#AddbankPageModule' },
  //{ path: 'details', loadChildren: './games/details/details.module#DetailsPageModule'},
  //{ path: 'game-transfer', loadChildren: './game-transfer/game-transfer.module#GameTransferPageModule' },
  //{ path: 'balance-transfer', loadChildren: './balance-transfer/balance-transfer.module#BalanceTransferPageModule' },
  //{ path: 'game-transfer-details', loadChildren: './game-transfer-details/game-transfer-details.module#GameTransferDetailsPageModule' },
  //{ path: 'payment', loadChildren: './deposit/payment/payment.module#PaymentPageModule' },
  //{ path: 'minigame', loadChildren: './minigame/minigame.module#MinigamePageModule' },
  //{ path: 'history', loadChildren: './history/history.module#HistoryPageModule' },
  //{ path: 'profile', loadChildren: './affiliate/profile/profile.module#ProfilePageModule' },
  //{ path: 'mostactive', loadChildren: './affiliate/mostactive/mostactive.module#MostactivePageModule' },
  //{ path: 'angpow', loadChildren: './minigame/angpow/angpow.module#AngpowPageModule' },
  
  //{ path: 'my/en-us/register/', loadChildren: './auth/signup/signup.module#SignupPageModule' },
  //{ path: 'register-en', loadChildren: './auth/signup/signup.module#SignupPageModule' },
  
  //{ path: 'login-2', loadChildren: './auth/login/login.module#LoginPageModule'},
  //{ path: 'password-reset', loadChildren: './account/forgetpassword/forgetpassword.module#ForgetpasswordPageModule' },
  
  //{ path: 'commision-withdrawal', loadChildren: './affiliate/commision-withdrawal/commision-withdrawal.module#CommisionWithdrawalPageModule'},
  //{ path: 'viewaffwithdrawal', loadChildren: './affiliate/viewaffwithdrawal/viewaffwithdrawal.module#ViewaffwithdrawalPageModule'},
  //{ path: 'maintenance', loadChildren: './maintenance/maintenance.module#MaintenancePageModule'},
  //{ path: 'jokergamelist', loadChildren: './jokergamelist/jokergamelist.module#JokergamelistPageModule' },
  //{ path: 'weeklyrebate', loadChildren: './weeklyrebate/weeklyrebate.module#WeeklyrebatePageModule' },
  
  //{ path: 'playtechgamelist', loadChildren: './playtechgamelist/playtechgamelist.module#PlaytechgamelistPageModule' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
