import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AlertController } from '@ionic/angular';
import { Storage } from '@ionic/storage';
import { TranslateService } from '@ngx-translate/core';

/*
  Generated class for the GlobalProvider provider.

  See https://angular.io/guide/dependency-injection for more info on providers
  and Angular DI.
*/
@Injectable()
export class GlobalProvider {
  public preferredlang: string;
  public myorder: string;
  public mywallet: string;
  public myresult: string;
  public mylivechat: string;
  public myaccount: string;
  public mycheckbet: string;
  public mysubmitbet: string;
  public mysubmitorder: string;
  public livechatlink:string;
  public lctabsEnabled:boolean = false;
  public showPopup:boolean = true;
  
  public myrequesttac: string;
  public app_name:string = "918k2ss";
  public app_secret: string = "hetk#$jslDewjsEjmwl45*35Nw";
  public usertoken: any;
  public userpasswd: string;
  public name:string;
  public email:string;
  public mybalance:string;
  public lmerchant:string;
  public lmindeposit:number;
  public lminbonusdeposit:number;
  public lrebate_per:number;
  public lmaxdeposit:number;
  public lminwithdrawal:number;
  public lminbonuswithdrawal:number;
  public lmaxwithdrawal:number;
  public lnumbermaxwithdraw:number;
  public mobileno: string;
  public username: string;
  public referralcode: string = "";
  public appversion:string = "1.0.0";
  public forceupdate:boolean;
  
  // the tab button
  public bhome:string = "Home";
  public bgame:string = "Bonus";
  public bhistory:string = "History";
  public baccount:string = "Account";
  public blivechat:string = "Livechat";
  public myValueAP:boolean = false;
  public myValuePR:boolean = false;
  public myValueAN:boolean = false;

  // production 
  //public url: string = "https://be.7esofts.com/api/";
  public url: string = "https://kiosk.v2appgold.app/api/";
  // sandbox
  //public url: string = "http://v2host/api/";
  public urlpage: string = "https://kissme.ddns.net/payment/";
  //public urlpage: string = "http://192.168.64.2/v2api/public/payment/";
  //public urlpayment: string = "http://35.240.142.0:8022/paynow/";
  public urlpayment: string = "https://kissme.ddns.net/surepay4/";
  //public urlpayment: string = "http://192.168.64.2/v2api/public/surepay2/";
  //public post_url:string = "http://192.168.64.2/v2api/public/surepost";
  public post_url:string = "https://kissme.ddns.net/api/surepost";
  //public return_url:string = "http://192.168.64.2/v2api/public/paymentcompleted/2";
  public return_url:string = "https://kissme.ddns.net/paymentcompleted/19";
  //public failed_return_url:string = "http://192.168.64.2/v2api/public/paymentcancelled/2";
  public failed_return_url:string = "https://kissme.ddns.net/paymentcancelled/19";

  // Gtelco
  public payment_key: string = "4c22bd444899d3b6047a10b20a2f26db";
  public payment_pass: string = "bitZR3lPS1M5OTlUUkVkWWhKNUIvdz09";

  /*public banklist = [ { bankname: 'Maybank', bankcode: '50000477', imageurl: '/assets/img/maybank.jpg', flag: false, imageurl2 : '/assets/img/maybank2.png' }, 
  { bankname: 'CIMB', bankcode: '50000479', imageurl: '/assets/img/cimb.jpg', flag: false, imageurl2 : '/assets/img/cimb2.png'}, 
   { bankname: 'Hong Leong', bankcode: '50000476', imageurl: '/assets/img/hongleong.jpg', flag: false, imageurl2 : '/assets/img/hongleong2.png'},
  { bankname: 'Public Bank', bankcode: '50000474', imageurl: '/assets/img/pbe.jpg', flag: false, imageurl2 : '/assets/img/pbe2.png'},
   { bankname: 'RHB', bankcode: '50000475', imageurl: '/assets/img/rhb.jpg', flag: false, imageurl2 : '/assets/img/rhb2.png'}];

   public cashbanklist = [ { bankname: 'MAYB', longname: "Maybank"}, 
   { bankname: 'PBE', longname: "Public Bank"}, 
   { bankname: 'CIMB', longname: "CIMB"}, 
   { bankname: 'HLB', longname: "Hong Leong Bank"}, 
   { bankname: 'RHB', longname: "RHB"}, 
   { bankname: 'AMB', longname: "AMB"}, 
   { bankname: 'BSN', longname: "BSN"}];*/

   /*public gamelist = [ { gamecode: '918kiss', imageurl: 'assets/img/addgame1.png', downloadlink: 'http://dm11.918kiss.com' }, 
  { gamecode: 'xe88', imageurl: '/assets/img/addxe88.png', downloadlink: 'http://dl.playalotgames.com/'},
  { gamecode: 'mega888', imageurl: '/assets/img/addmega888.png', downloadlink: 'http://m.mega693.com'}];*/

  public gamelist: any = [];
  public fromgamelist: any = [];
  public togamelist: any = [];
  
  private displaymsg: string;
  private displaytitle: string;
  public displaydrawdate: string;
  public tabsEnabled: boolean;

  constructor(public http: HttpClient, public alertController:AlertController, private storage: Storage, public translate:TranslateService) {
    this.preferredlang = "en";
    console.log('Hello GlobalProvider Provider');

    this.tabsEnabled = false;
    this.forceupdate = false;

    this.storage.get('tabsenabled').then((val) => {
      if (val != null) {
        this.tabsEnabled = val;
      }
      
    });
    this.storage.get('mobileno').then((val) => {
      if (val != null) {
        this.mobileno = val;
      }
      
    });
    // console.log("this mobile is " + this.mobileno);

    this.storage.get('preferredlang').then((val) => {
      if (val != null) {
        this.preferredlang = val;
      }

      this.translate.use(this.preferredlang);
      this.displaytab(this.preferredlang);
    });
    
    this.displaytab(this.preferredlang);
  }

  private StorageGet(Key: string, Default: any) {
    return new Promise((resolve, reject) => {
        this.storage.get(Key).then((data) => {
            // console.log("UserOptionsService<<------------Storage.get ", Key, data);
            resolve(data);
        })
            .catch(() => {
                // console.log("UserOptionsService------------>>Load DEFAULTS", Default);
                resolve(Default);
            });
    });
  }

  async showmth(smth:string) {
    if (smth == "01") {
      this.displaydrawdate = "Jan";
    } else if (smth == "02") {
      this.displaydrawdate = "Feb";
    } else if (smth == "03") {
      this.displaydrawdate = "Mar";
    } else if (smth == "04") {
      this.displaydrawdate =  "Apr";
    } else if (smth == "05") {
      this.displaydrawdate =  "May";
    } else if (smth == "06") {
      this.displaydrawdate =  "Jun";
    } else if (smth == "07") {
      this.displaydrawdate =  "Jul";
    } else if (smth == "08") {
      this.displaydrawdate =  "Aug";
    } else if (smth == "09") {
      this.displaydrawdate =  "Sep";
    } else if (smth == "10") {
      this.displaydrawdate =  "Oct";
    } else if (smth == "11") {
      this.displaydrawdate =  "Nov";
    } else if (smth == "12") {
      this.displaydrawdate =  "Dec";
    }
  }

  async displaytab(lang:string) {
    if (lang == "en") {
      this.myorder = "Order";
      this.mywallet = "Wallet";
      this.myresult = "Result";
      this.mylivechat = "LiveChat";
      this.myaccount = "Acount";
      this.mycheckbet = "Checking Bet";
      this.mysubmitbet = "Submitting Bet";
      this.mysubmitorder = "Submitting Order";
      this.myrequesttac = "Request TAC";
    } else if (lang == "cn") {
      this.myorder = "订单";
      this.mywallet = "钱包";
      this.myresult = "结果";
      this.mylivechat = "线聊";
      this.myaccount = "帐户";
      this.mycheckbet = "检查赌注...";
      this.mysubmitbet = "提交投注...";
      this.mysubmitorder = "提交订单..";
      this.myrequesttac = "索要TAC";
    } else {
      this.myorder = "Order";
      this.mywallet = "Dompet";
      this.myresult = "Keputusan";
      this.mylivechat = "LiveChat";
      this.myaccount = "Akaun";
      this.mycheckbet = "Semak Taruhan..";
      this.mysubmitbet = "Hantar Taruhan..";
      this.mysubmitorder = "Hantar Pesanan...";
      this.myrequesttac = "Minta TAC";
    }
  }

  async loadtoken() {
    this.storage.get('usertoken').then((val) => {
      if (val != null) {
        this.usertoken = val;
      }
      // console.log("usertoken is " + this.usertoken);
    });
  }

  async showAlert(atitle:string, amsg:string) {    
    //console.log('ShowAlert....');
    this.translate.use(this.preferredlang);

    this.translate.get(amsg).subscribe(
      value => {
        // value is our translated string
        this.displaymsg = value;
        console.log("calling translate showAlert" + this.displaymsg);
      }
    )

    this.translate.get(atitle).subscribe(
      value => {
        // value is our translated string
        this.displaytitle = value;
        console.log("calling translate showAlert" + this.displaytitle);
      }
    )
    /*if (this.preferredlang == 'en') { atitle = 'Alert'; amsg = 'Plese fill in the blank'}
    if (this.preferredlang == 'cn') { atitle = '资料'; amsg = '请填写空格'}
    if (this.preferredlang == 'my') { atitle = 'Alert'; amsg = 'Sila isikan kosong'}*/
    
    const alert = await this.alertController.create ({
        header: this.displaytitle,
     //   subHeader : this.displaytitle,
      message : this.displaymsg,
      buttons : ['OK']
    });

    await alert.present();
  }
}
